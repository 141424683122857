import { appBackendAppgainUrl, getConfigHeaders } from "../api";

const url = `UseCases`;
const defaultState = {
  useCases: [],
  useCase: {},
  useCaseImage: "",
  error: null,
  isPending: null,
  loaded: false,
};

const GET_USECASES = "GET_USECASES";
const DELETE_USECASE = "DELETE_USECASE";
const USECASES_PENDING = "USECASES_PENDING";
const USECASE_PENDING = "USECASE_PENDING";
const GET_USECASE = "GET_USECASE";
const USECASE_ERROR = "USECASE_ERROR";
const USECASES_ERROR = "USECASES_ERROR";
const EDIT_USECASE = "EDIT_USECASE";
const CREATE_USECASE = "CREATE_USECASE";

export const getUseCases = () => async (dispatch, getState) => {
  dispatch({
    type: USECASES_PENDING,
    payload: true,
  });
  try {
    const data = await appBackendAppgainUrl(
      `${url}`,
      "get",
      getConfigHeaders()
    );
    console.log(getState().InMemory);
    dispatch({
      type: GET_USECASES,
      payload: data.results,
    });
  } catch (error) {
    dispatch({
      type: USECASES_ERROR,
      payload: error,
    });
  }
};

// GET UseCase

export const getUseCase = useCaseId => async (dispatch, getState) => {
  dispatch({
    type: USECASE_PENDING,
    payload: true,
  });
  try {
    const data = await appBackendAppgainUrl(
      `${url}/${useCaseId}`,
      "get",
      getConfigHeaders()
    );
    dispatch({
      type: GET_USECASE,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USECASE_ERROR,
      payload: error,
    });
  }
};

// Create useCase
export const createUseCase = newUseCaseData => async (dispatch, getState) => {
  dispatch({
    type: USECASES_PENDING,
    payload: true,
  });
  try {
    const data = await appBackendAppgainUrl(
      `${url}`,
      "post",
      newUseCaseData,
      getConfigHeaders()
    );
    console.log(getState().InMemory);
    dispatch({
      type: CREATE_USECASE,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USECASES_ERROR,
      payload: error,
    });
  }
};

export const editUseCase = (id, newUseCaseData) => async dispatch => {
  dispatch({
    type: USECASE_PENDING,
    payload: true,
  });
  try {
    await appBackendAppgainUrl(
      `${url}/${id}`,
      "put",
      newUseCaseData,
      getConfigHeaders()
    );
    dispatch({
      type: EDIT_USECASE,
      payload: { id, newUseCaseData },
    });
  } catch (error) {
    dispatch({
      type: USECASE_ERROR,
      payload: error,
    });
  }
};

// Delete UseCase
export const deleteUseCase = id => async (dispatch, getState) => {
  dispatch({
    type: USECASES_PENDING,
    payload: true,
  });
  try {
    await appBackendAppgainUrl(`${url}/${id}`, "delete", getConfigHeaders());

    dispatch({
      type: DELETE_USECASE,
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: USECASES_ERROR,
      payload: error,
    });
  }
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case USECASE_PENDING:
    case USECASES_PENDING:
      return { ...state, isPending: true };

    case GET_USECASES:
      return {
        ...state,
        useCases: action.payload,
        loaded: true,
        isPending: false,
      };

    case CREATE_USECASE:
      return {
        useCases: [...state.useCases, action.payload],
        isPending: false,
        loaded: true,
      };

    case EDIT_USECASE:
      return {
        ...state,
        useCases: state.useCases.map(useCase =>
          useCase.objectId === action.payload.id
            ? action.payload.newUseCaseData
            : useCase
        ),
        isPending: false,
        loaded: true,
      };

    case GET_USECASE:
      return {
        ...state,
        useCase: action.payload,
        loaded: true,
        isPending: false,
      };

    case DELETE_USECASE:
      return {
        ...state,
        useCases: state.useCases.filter(
          person => person.objectId !== action.payload
        ),
        loaded: true,
        isPending: false,
      };
    default:
      return { ...state };
  }
}
