import React from "react";
import "./IconBtn.css";
import { AiOutlineEdit } from "react-icons/ai";
import { MdDelete } from "react-icons/md";

const IconBtn = ({ title, onClick, btnBehaviourClass, iconName }, props) => {
  return (
    <button
      title={title}
      onClick={onClick}
      className={`icon-btn ${btnBehaviourClass}-btn`}
      {...props}
    >
      {title === "Edit" ? <AiOutlineEdit size={20} />:<MdDelete size={20} />}

    </button>
  );
};

export default IconBtn;
