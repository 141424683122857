import { appBackendAppgainUrl, getConfigHeaders } from "../api";
import uploadOnS3 from "../helpers/uploadOnS3";

const defaultState = {
  FAQs: [],
  FAQ: {},
  error: null,
  isPending: null,
  loaded: false,
  msg: "",
};

const url = "FAQs";

const EDIT_FAQ = "EDIT_FAQ";
const CREATE_FAQ = "CREATE_FAQ";
const DELETE_FAQ = "DELETE_FAQ";
const LOAD_FAQS_LIST = "LOAD_FAQS_LIST";
const GET_FAQ = "GET_FAQ";
const FAQ_PENDING = "FAQ_PENDING";
const FAQS_ERROR = "FAQS_ERROR";

export const getFAQs = () => async (dispatch, getState) => {
  dispatch({ type: FAQ_PENDING });
  try {
    const data = await appBackendAppgainUrl(
      `${url}`,
      "get",
      getConfigHeaders()
    );
    dispatch({
      type: LOAD_FAQS_LIST,
      payload: data.results,
    });
  } catch (error) {
    dispatch({
      type: FAQS_ERROR,
      error,
    });
  }
};

export const getFAQ = (id) => async (dispatch, getState) => {
  dispatch({ type: FAQ_PENDING });
  try {
    const data = await appBackendAppgainUrl(
      `${url}/${id}`,
      "get",
      getConfigHeaders()
    );
    dispatch({
      type: GET_FAQ,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: FAQS_ERROR, error });
  }
};

export const editFAQ = (id, newFAQData) => async (dispatch, getState) => {
  dispatch({ type: FAQ_PENDING });
  try {
    await appBackendAppgainUrl(
      `${url}/${id}`,
      "put",
      newFAQData,
      getConfigHeaders()
    );
    dispatch({
      type: EDIT_FAQ,
      payload: { id, newFAQData },
    });
  } catch (error) {
    dispatch({ type: FAQS_ERROR, error });
  }
};

export const createFAQ = (newFAQData) => async (dispatch, getState) => {
  dispatch({ type: FAQ_PENDING });
  try {
    const res = await appBackendAppgainUrl(
      `${url}`,
      "post",
      newFAQData,
      getConfigHeaders()
    );
    dispatch({
      type: CREATE_FAQ,
      payload: res,
    });
  } catch (error) {
    dispatch({ type: FAQS_ERROR, error });
  }
};

export const deleteFAQ = (id) => async (dispatch, getState) => {
  dispatch({ type: FAQ_PENDING });
  try {
    await appBackendAppgainUrl(`${url}/${id}`, "delete", getConfigHeaders());
    dispatch({
      type: DELETE_FAQ,
      payload: id,
    });
  } catch (error) {
    dispatch({ type: FAQS_ERROR, error });
  }
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case LOAD_FAQS_LIST:
      return {
        ...state,
        isPending: false,
        error: false,
        FAQs: action.payload,
        loaded: true,
      };
    case GET_FAQ:
      return {
        ...state,
        FAQ: action.payload,
        loaded: true,
        isPending: false,
      };
    case FAQ_PENDING:
      return {
        ...state,
        isPending: true,
        error: false,
        loaded: false,
      };

    case FAQS_ERROR:
      return {
        ...state,
        isPending: false,
        error: action.error,
        FAQs: [],
        loaded: true,
      };

    case CREATE_FAQ:
      return {
        ...state,
        isPending: false,
        error: false,
        FAQs: [...state.FAQs, action.payload],
        loaded: true,
      };

    case EDIT_FAQ:
      return {
        ...state,
        isPending: false,
        error: false,
        loaded: true,
        FAQs: state.FAQs.map((FAQ) =>
          FAQ.objectId == action.payload.id ? action.payload.newFAQData : FAQ
        ),
      };

    case DELETE_FAQ:
      return {
        ...state,
        isPending: false,
        error: false,
        FAQs: state.FAQs.filter((FAQ) => FAQ.objectId != action.payload),
        loaded: true,
      };
    default:
      return state;
  }
}
