import './Auth.css'

import React, { useState, useEffect } from 'react';
import {
    FormControl,
    FormGroup,
    Button,
} from "react-bootstrap";
import { connect } from 'react-redux';
import { checkUser } from '../../reducers/Auth';
import toaster from "../../modules/toaster";

const Auth = ({checkUser,error}) => {
    const [username, setUsername] = useState('');
    const [pass, setPass] = useState('');
    const handleLogin = async(username, pass, e) => {
        e.preventDefault();
        checkUser(username, pass);
        try {
            const response = await checkUser(username, pass);
            localStorage.setItem("user",response.username)
            sessionStorage.setItem("user",response.username)
    
            toaster.success(`Welcome ${response.username}`);
          } catch (e) {
            console.error(e?.message || e || error);
            toaster.error(e?.message || e || error);
          }  
    }
    
    return (
        <div className='auth'>
            <form
                style={{ minHeight: "auto" }}
                onSubmit={(e) => handleLogin(username, pass, e)}
            >
                <FormGroup>

                    <div className="fieldDiv">
                        <label id="username">Username</label>
                        <FormControl
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            name="username"
                            htmlFor="username"
                            placeholder="Enter Username"
                            required
                        />
                    </div>
                   
                    <div className="fieldDiv">
                        <label id="password">Password</label>
                        <FormControl
                            type="password"
                            value={pass}
                            onChange={(e) => setPass(e.target.value)}
                            name="password"
                            htmlFor="password"
                            placeholder="Enter Password"
                            required
                        />
                    </div>
                 
                </FormGroup>
                <div className="btns">
                    <Button className="submitBtn" type="submit" bsStyle="primary">
                        Login
                    </Button>
                </div>
            </form>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isPending: state.Auth.isPending,
    error: state.Auth.error,
    user: state.Auth.user,
    isLogged: state.Auth.isLogged
})

const actions = (dispatch) => ({
    checkUser: (username, email, pass) => dispatch(checkUser(username, email, pass))
})

export default connect(mapStateToProps, actions)(Auth);