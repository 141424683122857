import React from "react";
import {ImSpinner} from "react-icons/im"
const LoadingSpinner = () => {
  return (
    <div className="loader-container">
      <div className="loader">
        <ImSpinner size={24} />
      </div>
    </div>
  );
};

export default LoadingSpinner;
