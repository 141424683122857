import "./AddUseCases.css";
import { FormControl, FormGroup, Button } from "react-bootstrap";

import React, { useState, useEffect } from "react";

import { EditorState, convertFromHTML, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { connect } from "react-redux";
import {
  getUseCase,
  createUseCase,
  editUseCase,
} from "../../reducers/UseCases";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { stateToHTML } from "draft-js-export-html";
import toaster from "../../modules/toaster";
const AddUseCase = ({
  history,
  isPending,
  useCase,
  getUseCase,
  createUseCase,
  editUseCase,
}) => {
  const useCaseId =
    window.location.hash.includes("editUseCase") &&
    window.location.hash.split("/")[window.location.hash.split("/").length - 1];
  console.log("useCaseId: ", useCaseId);
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [name, setName] = useState("");
  const [content, setContent] = useState(EditorState.createEmpty());
  const [isContentRequired, setIsContentRequired] = useState(false);
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");

  const [industry, setIndustry] = useState("");

  const [edit, setEdit] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (useCaseId) {
      getUseCase(useCaseId);
      setIsEditingMode(true);
    } else {
      setIsEditingMode(false);
    }
  }, [useCaseId]);

  const cancel = () => {
    history.go(-1);
  };

  const submitUseCaseHandler = (e) => {
    e.preventDefault();
    if (content.getCurrentContent().getPlainText().trim().length <= 0) {
      setIsContentRequired(true);
      return;
    }
    const useCaseData = {
      Name: name,
      Content: stateToHTML(content.getCurrentContent()),
      Description: description,
      Industry: industry,
      Category: category,
    };

    if (isEditingMode) {
      try {
        editUseCase(useCaseId, useCaseData);
        toaster.success("updating successfully");
      } catch (error) {
        toaster.error("something went wrong");
      }
    } else {
      try {
        createUseCase(useCaseData);
        toaster.success("updating successfully");
      } catch (error) {
        toaster.error("something went wrong");
      }
    }
    // wipeAmbassadorImageData();
    history.push("/UseCases");
  };

  useEffect(() => {
    if (!!useCase && useCaseId) {
      setName(useCase.Name);
      setCategory(useCase.Category);

      setIndustry(useCase.Industry);
      setContent(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(
              useCase.Content ? useCase.Content : `<p>No Content</p>`
            )
          )
        )
      );
      setDescription(useCase.Description);
      setEdit(true);
    }
  }, [useCase]);

  if (isPending) return <LoadingSpinner />;

  return (
    <div className="inputcontainer">
      <h2 className="title">
        {isEditingMode ? `Edit UseCase: ${name}` : "Add New UseCase"}
      </h2>
      <form
        className="container"
        style={{ minHeight: "auto" }}
        onSubmit={submitUseCaseHandler}
      >
        <FormGroup>
          <div className="fieldDiv">
            <label id="useCaseName">UseCase Name</label>
            <FormControl
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              name="useCaseName"
              htmlFor="useCaseName"
              placeholder="Enter Name"
              required
            />
          </div>

          <div className="fieldDiv">
            <label id="useCaseDescription">Description</label>
            <FormControl
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              name="useCaseDescription"
              htmlFor="useCaseDescription"
              placeholder="Enter Description"
              required
            />
          </div>

          <div className="fieldDiv">
            <label id="useCaseCategory">Category</label>
            <FormControl
              type="text"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              name="useCaseCategory"
              htmlFor="useCaseCategory"
              placeholder="Enter Category"
              required
            />
          </div>

          <div className="fieldDiv">
            <label id="useCaseIndustry">Industry</label>
            <FormControl
              type="text"
              value={industry}
              onChange={(e) => setIndustry(e.target.value)}
              name="useCaseIndustry"
              htmlFor="useCaseIndstry"
              placeholder="Enter Industry"
              required
            />
          </div>

          <div className="fieldDiv">
            <label id="useCaseContent">Content</label>
            <Editor
              editorState={content}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={(e) => setContent(e)}
              onFocus={() => setIsContentRequired(false)}
              onBlur={() =>
                content.getCurrentContent().getPlainText().trim().length <= 0 &&
                setIsContentRequired(true)
              }
            />

            {isContentRequired && (
              <p
                style={{
                  color: "red",
                  textAlign: "center",
                  fontWeight: 700,
                }}
              >
                Content field is required
              </p>
            )}
          </div>
        </FormGroup>

        <div className="btns">
          <Button className="submitBtn" type="submit" bsStyle="primary">
            {isEditingMode ? "Edit" : "Submit"}
          </Button>
          <Button className="CancelBtn" bsStyle="primary" onClick={cancel}>
            Cancel
          </Button>
        </div>
        <div className="error">
          <p>{error}</p>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isPending: state.UseCases.isPending,
  useCase: state.UseCases.useCase,
});

const actions = (dispatch) => ({
  getUseCase: (useCaseId) => dispatch(getUseCase(useCaseId)),
  createUseCase: (newUseCaseData) => dispatch(createUseCase(newUseCaseData)),
  editUseCase: (useCaseId, newUseCaseData) =>
    dispatch(editUseCase(useCaseId, newUseCaseData)),
});

export default connect(mapStateToProps, actions)(AddUseCase);
