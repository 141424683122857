import React from "react";
import { connect } from "react-redux";
import { toggleMobileNavVisibility } from "../../reducers/Layout";

import { FiLogOut } from "react-icons/fi";

const Header = ({
  showMobileMenu,
  toggleMobileNavVisibility,
}) => {
  const handleLogout = () => {
    sessionStorage.clear();
    window.location.reload(false);
  }
  return (
    <div className="header navbar mobile">
    <div
      style={{
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        width: "100%",
        padding: "0 20px",
      }}
    >


      <button
        className="logout"
        onClick={handleLogout}
      >
        <FiLogOut size={24} color="red" />
        Log out
      </button>
    </div>
  </div>

  );
};

const mapStateToProp = (state) => ({

});

const mapDispatchToProp = (dispatch) => ({
  toggleMobileNavVisibility: () => dispatch(toggleMobileNavVisibility()),
});

export default connect(mapStateToProp, mapDispatchToProp)(Header);
