import {  Snackbar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { getPlan, editPlan, createPlan } from "../../reducers/Plans";
import MuiAlert from "@material-ui/lab/Alert";
import "./Plans.css";
const projectType = [
  { value: "mobile_app", label: "mobile app" },
  {
    value: "mobile_marketing",
    label: "mobile marketing app",
  },
];
const commonFeaturesOptions = [
  "Advanced Analytics",
  "Marketing analytics",
  "White Labeling",
  "Export to CSV / Exel",
  "Custom Domain",
  "integratons",
  "seo friendness",
  "custom Integration",
  "automatic database backup",
  "data transfer",
  "Background Jobs",
  "Unistall Tracking",
  "User funnel",
  "File Storage",
  "Database Storage",
];
const offersvalues = [
  "marketing links",
  "app boost",
  "smart retargeting",
  "app backend",
  "mobile attribution",
];
const AddPlans = ({
  history,
  isPending,
  getPlan,
  plan,
  createPlan,
  editPlan,
}) => {
  const planId =
    window.location.hash.includes("editPlan") &&
    window.location.hash.split("/")[window.location.hash.split("/").length - 1];

  const [isEditingMode, setIsEditingMode] = useState(false);
  // commonFeature
  const [commonRows, setCommonRows] = useState([0]);
  const [count, SetCount] = useState(1);
  // product
  const [productRows, setProductRows] = useState([0]);
  const [countProduct, SetCountProduct] = useState(1);
  const [formData, setFormData] = useState({
    values: {
      common_features: [
        {
          label: [
            {
              label: "",
              local: "en",
              summary: "",
            },
          ],
          name: "Advanced Analytics",
          id: 0,
        },
      ],
      interval_count: 1,
      project_type: "mobile_app",
      price: 0,
      status: "active",
      level: 0,
      label: [
        {
          label: "",
          local: "en",
        },
      ],
      offers_values: "marketing links",
      currency: "USD",
      name: "",
      trial_period_days: 30,
      interval: "month",
      statement_descriptor: "",
      user_type: "self",
      product: [
        {
          limit: [
            {
              type: "",
              name: "",
              value: 0,
            },
          ],
          label: [
            {
              label: "",
              local: "en",
              summary: "",
            },
          ],
          name: "",
          order: 0,
          id: 0,
        },
      ],
    },
  });
  useEffect(() => {
    setIsEditingMode(true);
    getPlan(planId);
    if (planId && Object.keys(plan).length != 0) {
      setFormData((prevState) => {
        return {
          values: {
            ...prevState.values,
            name: plan.name,
            project_type: plan.project_type,
            label: [
              {
                local: plan.label[0].local,

                label: plan.label[0].label,
              },
            ],
            price: plan.price,
            currency: plan.currency,
            interval: plan.interval,
            interval_count: plan.interval_count,
            trial_period_days: plan.trial_period_days,
            statement_descriptor: plan.statement_descriptor,
            status: plan.status,
            level: plan.level,
            common_features: plan.common_features.map((feature, index) => {
              return {
                label: [
                  {
                    label: feature.label[0].label,
                    local: feature.label[0].local,
                    summary: feature.label[0].summary,
                  },
                ],
                name: feature.name,
                id: index,
              };
            }),

            product: plan.product.map((product, index) => {
              return {
                limit: [
                  {
                    type: product.limit ? product.limit[0].type : "",
                    name: product.limit ? product.limit[0].name : "",
                    value: product.limit ? product.limit[0].value : 0,
                  },
                ],
                label: [
                  {
                    label: product.label[0].label,
                    local: product.label[0].local,
                    summary: product.label[0].summary,
                  },
                ],
                name: product.name,
                order: product.order,
                id: index,
              };
            }),
          },
        };
      });

      // handleCommonRows();
      // handleProductRows();
      // SetCount()
    } else {
      setIsEditingMode(false);
    }
  }, [planId, Object.keys(plan).length]);

  useEffect(() => {}, [commonRows]);

  const handleSubmit = (e) => {
    e.preventDefault();

    for (let feature of formData.values.common_features) {
      delete feature.id;
    }
    for (let product of formData.values.product) {
      delete product.id;
    }

    if (isEditingMode) {
      editPlan(planId, formData.values).then(() => {
        return (
          <Snackbar autoHideDuration={6000}>
            <Alert severity="success">edit successfully</Alert>
          </Snackbar>
        );
      });
    } else {
      createPlan(formData.values).then(() => {
        return (
          <Snackbar autoHideDuration={6000}>
            <Alert severity="success">created successfully</Alert>
          </Snackbar>
        );
      });
    }
    history.push("/plans");
  };
  // for all
  const handleChange = (e) => {
    let { name, value } = e.target;
    if (
      name === "level" ||
      name === "interval_count" ||
      name === "price" ||
      name === "trial_period_days"
    ) {
      value = parseInt(value, 10);
    } else {
      value = value.toString();
    }
    setFormData((prevState) => {
      return {
        values: {
          ...prevState.values,
          [name]: value,
        },
      };
    });
  };
  // for commonFeature
  const handleFeatureLabelChange = (e, keyId) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        common_features: prevState.values.common_features.map((key, index) => {
          if (key.id === keyId) {
            return {
              ...key,
              label: key.label.map((l) => ({
                ...l,
                [name]: value,
              })),
            };
          }
          return key;
        }),
      },
    }));
  };
  const featureNameChange = (keyId, e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        common_features: prevState.values.common_features.map((key, index) => {
          if (key.id === keyId) {
            return {
              ...key,

              name: value,
            };
          }
          return key;
        }),
      },
    }));
  };
  const removeCommon = (id) => {
    SetCount(count - 1);
    let rows = commonRows.filter((num) => num != id);

    setCommonRows(rows);
    setFormData((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        common_features: prevState.values.common_features.filter(
          (feature) => feature.id !== id
        ),
      },
    }));
  };
  const addCommon = (e) => {
    SetCount(count + 1);
    setCommonRows([...commonRows, count]);

    setFormData((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        common_features: [
          ...prevState.values.common_features,
          {
            label: [
              {
                label: "",
                local: "",
                summary: "",
              },
            ],
            name: "",
            id: prevState.values.common_features.length,
          },
        ],
      },
    }));
  };
  // for label array
  const labelChange = (newValue) => {
    setFormData((prevState) => {
      const newValues = prevState.values.label.map((key) => {
        return {
          ...key,
          label: newValue,
        };
      });
      return {
        values: {
          ...prevState.values,
          label: newValues,
        },
      };
    });
  };
  // for product
  const removeProduct = (id) => {
    SetCountProduct(countProduct - 1);
    let rows = productRows.filter((num) => num != id);

    setProductRows(rows);
    setFormData((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        product: prevState.values.product.filter(
          (feature) => feature.id !== id
        ),
      },
    }));
  };
  const addProduct = (e) => {
    SetCountProduct(countProduct + 1);
    setProductRows([...productRows, countProduct]);

    setFormData((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        product: [
          ...prevState.values.product,
          {
            limit: [
              {
                type: "",
                name: "",
                value: 10,
              },
            ],
            label: [
              {
                label: "",
                local: "",
                summary: "",
              },
            ],
            name: "",
            order: 0,
            id: prevState.values.product.length,
          },
        ],
      },
    }));
  };
  const productChange = (e, keyId) => {
    let { name, value } = e.target;
    if (name === "order") {
      value = parseInt(value, 10);
    } else {
      value = value.toString();
    }
    setFormData((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        product: prevState.values.product.map((key, index) => {
          if (key.id === keyId) {
            return {
              ...key,

              [name]: value,
            };
          }
          return key;
        }),
      },
    }));
  };
  const handleProductLimitChange = (e, keyId) => {
    let { name, value } = e.target;
    if (name === "value") {
      value = parseInt(value, 10);
    } else {
      value = value.toString();
    }
    setFormData((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        product: prevState.values.product.map((key, index) => {
          if (key.id === keyId) {
            return {
              ...key,
              limit: key.limit.map((l) => ({
                ...l,
                [name]: value,
              })),
            };
          }
          return key;
        }),
      },
    }));
  };
  const handleProductlabelChange = (e, keyId) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        product: prevState.values.product.map((key, index) => {
          if (key.id === keyId) {
            return {
              ...key,
              label: key.label.map((l) => ({
                ...l,
                [name]: value,
              })),
            };
          }
          return key;
        }),
      },
    }));
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  if (isPending) return <LoadingSpinner />;
  return (
    <React.Fragment>
      <h2 className="title">{isEditingMode ? `Edit Plan` : "Add New Plan"}</h2>

      <section className="FormContainer">
        {/* onSubmit={handleSubmit} */}
        <form onSubmit={handleSubmit}>
          <h3>Plan Attributes</h3>
          {/* Plan Name */}
          <div className="formGroup">
            <label className="label required">Plan Name :</label>
            <input
              name="name"
              // value={name}
              className="input"
              type="text"
              required
              // onChange={handleInputChange}
              value={formData.values.name}
              onChange={(e) => handleChange(e)}
            />
          </div>
          {/* project type */}
          <div className="formGroup">
            <label className="label required">project type :</label>
            <select
              name="project_type"
              className="input"
              required
              value={formData.values.project_type}
              onChange={(e) => handleChange(e)}
            >
              {projectType.map((option, index) => {
                return (
                  <option value={option.value} key={index}>
                    {option.label}
                  </option>
                );
              })}
            </select>
          </div>
          {/* Plan Label Language */}
          <div className="formGroup">
            <label className="label required">Plan Label Language :</label>
            <select
              className="input"
              required
              value={formData.values.label[0].local}
              onChange={(e) =>
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  label: [
                    {
                      ...prevFormData.label[0],
                      local: e.target.value,
                    },
                  ],
                }))
              }
            >
              <option value="en">English</option>
            </select>
          </div>
          {/*  Label  */}
          <div className="formGroup">
            <label className="label required">Label :</label>
            <input
              name="label"
              className="input"
              type="text"
              required
              value={formData.values.label[0].label}
              onChange={(e) => {
                labelChange(e.target.value);
              }}
            />
          </div>
          {/* price */}
          <div className="formGroup">
            <label className="label required">Price :</label>
            <input
              name="price"
              // value={price}
              className="input"
              type="number"
              required
              value={formData.values.price}
              onChange={(e) => handleChange(e)}
            />
          </div>
          {/*currency  */}
          <div className="formGroup">
            <label className="label ">currency:</label>
            <select
              name="currency"
              className="input"
              required
              value={formData.values.currency}
              onChange={(e) => handleChange(e)}
            >
              <option value="USD">USD</option>
            </select>
          </div>
          {/* offers_values */}
          <div className="formGroup">
            <label className="label ">offers values</label>

            <select
              name="offers_values"
              className="input"
              required
              value={formData.values.offers_values}
              onChange={(e) => handleChange(e)}
            >
              {offersvalues.map((option, index) => {
                return (
                  <option value={option} key={index}>
                    {option}
                  </option>
                );
              })}
            </select>
          </div>
          {/* interval */}
          <div className="formGroup">
            <label className="label ">interval</label>
            <select
              name="interval"
              className="input"
              // value={interval}
              required
              value={formData.values.interval}
              onChange={(e) => handleChange(e)}
            >
              <option value="month">month</option>
              <option value="year">year</option>
            </select>
          </div>
          {/* interval_count */}
          <div className="formGroup">
            <label className="label ">interval count</label>
            <input
              name="interval_count"
              className="input"
              type="number"
              // value={interval_count}
              required
              value={formData.values.interval_count}
              onChange={(e) => handleChange(e)}
            />
          </div>
          {/* trial_period_days */}
          <div className="formGroup">
            <label className="label ">trial days</label>
            <input
              name="trial_period_days"
              className="input"
              // value={trial_period_days}
              type="number"
              required
              value={formData.values.trial_period_days}
              onChange={(e) => handleChange(e)}
            />
          </div>
          {/* statement_descriptor */}
          <div className="formGroup">
            <label className="label ">statement descriptor</label>
            <input
              name="statement_descriptor"
              // value={statement_descriptor}
              className="input"
              type="text"
              required
              value={formData.values.statement_descriptor}
              onChange={(e) => handleChange(e)}
            />
          </div>
          {/*status  */}
          <div className="formGroup">
            <label className="label ">status:</label>
            <select
              name="status"
              type="text"
              className="input"
              required
              value={formData.status}
              onChange={(e) => handleChange(e)}
            >
              <option value="active">active</option>
              <option value="inactive">inactive</option>
            </select>
          </div>
          {/* level */}
          <div className="formGroup">
            <label className="label ">level</label>
            <input
              name="level"
              className="input"
              type="number"
              required
              value={formData.values.level}
              onChange={(e) => handleChange(e)}
            />
          </div>

          <h3>common features</h3>
          {/* common_features.name */}
          {formData.values.common_features.length >= 1 &&
            formData.values.common_features.map((rowNum, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="formGroup">
                    <label className="label required">common features:</label>
                    <select
                      name="name"
                      value={formData.values.common_features[index].name}
                      onChange={(e) => {
                        featureNameChange(index, e);
                      }}
                      className="input"
                      required
                    >
                      {commonFeaturesOptions.map((option, index) => {
                        return (
                          <option value={option} key={index}>
                            {option}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {/* common_features.label.label */}
                  <div className="formGroup">
                    <span className="label "></span>

                    <input
                      placeholder="Common Feature Label"
                      name="label"
                      className="input"
                      type="text"
                      required
                      value={
                        formData.values.common_features[index].label[0].label
                      }
                      onChange={(e) => {
                        handleFeatureLabelChange(e, index);
                      }}
                    />
                  </div>
                  {/*  common_features.label.local */}
                  <div className="formGroup">
                    <span className="label "></span>

                    <select
                      placeholder="Please select languge"
                      name={"local"}
                      className="input"
                      required
                      value={
                        formData.values.common_features[index].label[0].local
                      }
                      onChange={(e) => {
                        handleFeatureLabelChange(e, index);
                      }}
                    >
                      <option value="en">English</option>
                      <option value="ar">Arabic</option>
                    </select>
                  </div>
                  {/* common_features.label.summary */}
                  <div className="formGroup">
                    <span className="label"></span>

                    <input
                      placeholder="Common Feature Label Summary"
                      name="summary"
                      className="input"
                      type="text"
                      required
                      value={
                        formData.values.common_features[index].label[0].summary
                      }
                      onChange={(e) => {
                        handleFeatureLabelChange(e, index);
                      }}
                    />
                    <i
                      class="fa fa-minus-circle"
                      aria-hidden="true"
                      onClick={() => {
                        removeCommon(index);
                      }}
                    ></i>
                  </div>
                </React.Fragment>
              );
            })}
          {/* common_features add */}
          <div className="formGroup">
            <span className="label"></span>
            <button
              type="button"
              className="addButton"
              onClick={() => {
                addCommon();
              }}
            >
              <i class="fa fa-plus" aria-hidden="true"></i> Add Common Feature
            </button>
          </div>

          <h3>product</h3>

          {formData.values.product.map((product, index) => {
            return (
              <React.Fragment>
                <div className="formGroupForProduct">
                  <label className="label required">Name</label>
                  <input
                    placeholder=" Product name"
                    name="name"
                    className="input"
                    style={{ width: "74%" }}
                    type="text"
                    value={formData.values.product[index].name}
                    required
                    onChange={(e) => {
                      productChange(e, index);
                    }}
                  />
                </div>
                {/* product.order */}
                <div className="formGroup">
                  <label className="label ">order</label>

                  <input
                    placeholder="0"
                    name="order"
                    className="input"
                    type="number"
                    required
                    value={formData.values.product[index].order}
                    onChange={(e) => {
                      productChange(e, index);
                    }}
                  />
                </div>
                {/*  product.label.label */}
                <div className="formGroup">
                  <span className="label ">label</span>

                  <input
                    placeholder="product Label"
                    type={"text"}
                    name={"label"}
                    className="input"
                    value={formData.values.product[index].label[0].label}
                    required
                    onChange={(e) => {
                      handleProductlabelChange(e, index);
                    }}
                  />
                </div>
                {/*  product.label.local */}
                <div className="formGroup">
                  <span className="label "></span>

                  <select
                    placeholder="Please select languge"
                    name={"local"}
                    className="input"
                    value={formData.values.product[index].label[0].local}
                    required
                    onChange={(e) => {
                      handleProductlabelChange(e, index);
                    }}
                  >
                    <option value="en">English</option>
                    <option value="ar">Arabic</option>
                  </select>
                </div>
                {/* product.label.summary */}
                <div className="formGroup">
                  <span className="label"> Summary</span>

                  <input
                    placeholder="product Summary"
                    name="summary"
                    className="input"
                    type="text"
                    value={formData.values.product[index].label[0].summary}
                    required
                    onChange={(e) => {
                      handleProductlabelChange(e, index);
                    }}
                  />
                </div>

                {/*  product.limit */}

                <div className="formGroup">
                  <span className="label ">limit</span>

                  <input
                    placeholder="Limit Name"
                    type={"text"}
                    name={"name"}
                    className="input"
                    // required
                    value={formData.values.product[index].limit[0].name}
                    onChange={(e) => {
                      handleProductLimitChange(e, index);
                    }}
                  />
                </div>
                {/*  product.limit.type */}
                <div className="formGroup">
                  <span className="label ">label</span>

                  <input
                    placeholder="Limit Type"
                    type={"text"}
                    name={"type"}
                    className="input"
                    // required
                    value={formData.values.product[index].limit[0].type}
                    onChange={(e) => {
                      handleProductLimitChange(e, index);
                    }}
                  />
                </div>
                {/*  product.limit.value */}
                <div className="formGroup">
                  <span className="label "></span>

                  <input
                    placeholder="Limit Value"
                    type={"number"}
                    name={"value"}
                    className="input"
                    // required
                    value={formData.values.product[index].limit[0].value}
                    onChange={(e) => {
                      handleProductLimitChange(e, index);
                    }}
                  />
                  <i
                    class="fa fa-minus-circle"
                    aria-hidden="true"
                    onClick={() => {
                      removeProduct(index);
                    }}
                  ></i>
                </div>
              </React.Fragment>
            );
          })}
          <div className="formGroup">
            <span className="label"></span>
            <button
              type="button"
              className="addButton"
              onClick={() => {
                addProduct();
              }}
            >
              <i class="fa fa-plus" aria-hidden="true"></i> Add product Feature
            </button>
          </div>
          {/* product.name */}
          <button
            type="submit"
            className="btn btn-primary "
            style={{ marginTop: "1em", marginBottom: "1em" }}
          >
            submit
          </button>
        </form>
      </section>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({
  isPending: state.Plans.isPending,
  plan: state.Plans.plan,
});

const actions = (dispatch) => ({
  getPlan: (planId) => dispatch(getPlan(planId)),
  editPlan: (planId, editPlanData) => dispatch(editPlan(planId, editPlanData)),
  createPlan: (newPlanData) => dispatch(createPlan(newPlanData)),
});

export default connect(mapStateToProps, actions)(AddPlans);
