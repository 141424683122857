import "./AddCustomer.css";
import { FormControl, FormGroup, Button } from "react-bootstrap";

import React, { useState, useEffect } from "react";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { connect } from "react-redux";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import _ from "lodash";
import { getFAQ, createFAQ, editFAQ } from "../../reducers/FAQs";
import toaster from "../../modules/toaster";
const AddFAQ = ({ history, isPending, FAQ, getFAQ, createFAQ, editFAQ }) => {
  const FAQId =
    window.location.hash.includes("editFAQ") &&
    window.location.hash.split("/")[window.location.hash.split("/").length - 1];
  console.log("FAQId: ", FAQId);
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  const [edit, setEdit] = useState(false);
  const [error, setError] = useState("");

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (FAQId) {
      getFAQ(FAQId);
      setIsEditingMode(true);
    } else {
      setIsEditingMode(false);
    }
  }, [FAQId]);

  const cancel = () => {
    history.go(-1);
  };

  const submitFAQHandler = (e) => {
    e.preventDefault();

    const FAQData = {
      question: question,
      answer: answer,
    };
console.log(FAQData);
    if (isEditingMode) {
      try {
        editFAQ(FAQId, FAQData);
        toaster.success("updated successfully");
      } catch (error) {
        toaster.error("something went wrong");
      }
    } else {
      try {
        createFAQ(FAQData);
        toaster.success("updated successfully");
      } catch (error) {
        toaster.error("something went wrong");
      }
    }
    history.push("/FAQs");
  };

  useEffect(() => {
    if (!!FAQ && FAQId) {
      setQuestion(FAQ.question);
      setAnswer(FAQ.answer);
      setEdit(true);
    }
  }, [FAQ]);

  if (isPending) return <LoadingSpinner />;

  return (
    <div className="inputcontainer">
      <h2 className="title">
        {isEditingMode ? `Edit FAQ: ${FAQ.question}` : "Add New FAQ"}
      </h2>
      <form
        className="container"
        style={{ minHeight: "auto" }}
        onSubmit={submitFAQHandler}
      >
        <FormGroup>
          <div className="fieldDiv">
            <label id="question">question</label>
            <FormControl
              type="text"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              name="question"
              htmlFor="question"
              placeholder="Enter question"
              required
            />
          </div>
          <div className="fieldDiv">
            <label id="answer">answer</label>
            <FormControl
              type="text"
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
              name="answer"
              htmlFor="answer"
              placeholder="Enter answer"
              required
            />
          </div>
        </FormGroup>
        <div className="btns">
          <Button
            className="submitBtn"
            type="submit"
            bsStyle="primary"
          >
            {isEditingMode ? "Edit" : "Submit"}
          </Button>
          <Button className="CancelBtn" bsStyle="primary" onClick={cancel}>
            Cancel
          </Button>
        </div>
        {show && (
          <ConfirmModal
            handleClose={() => setShow(false)}
            handleShow={() => setShow(true)}
            message={`Oops! Image size is more than 500 kilobyte! Please Choose smaller size image`}
            setShow={setShow}
            show={show}
          ></ConfirmModal>
        )}
        <div className="error">
          <p>{error}</p>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isPending: state.FAQs.isPending,
  FAQ: state.FAQs.FAQ,
});

const actions = (dispatch) => ({
  getFAQ: (FAQId) => dispatch(getFAQ(FAQId)),
  createFAQ: (newFAQData) => dispatch(createFAQ(newFAQData)),
  editFAQ: (FAQId, newFAQData) => dispatch(editFAQ(FAQId, newFAQData)),
});

export default connect(mapStateToProps, actions)(AddFAQ);
