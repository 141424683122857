import React, { useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import "../../assets/styles/base.scss";
import { HiArrowCircleDown, HiVideoCamera } from "react-icons/hi";
import { PiFloppyDiskBackFill } from "react-icons/pi";
import { AiOutlineAreaChart } from "react-icons/ai";
import { MdCircleNotifications } from "react-icons/md";
import {FcFaq} from "react-icons/fc";
import { IoBookSharp } from "react-icons/io5";



const Nav = ({ location, mobileMenu }) => {
  const [productPage, setProductPage] = useState(false);
  const [pathname, setPathname]=useState(location.pathname);


  return (
    <ul className="nav">
      <li className={pathname === "/customers" ? "active" : null}>
        <Link to="/customers" onClick={()=>{setPathname("/customers")}}>
          <HiVideoCamera size={24}/>
          <p>Customers</p>
        </Link>
      </li>

      <li className={pathname === "/useCases" ? "active" : null}>
        <Link to="/useCases" onClick={()=>{setPathname("/useCases")}}>
          <PiFloppyDiskBackFill size={24} />
          <p>Use Cases</p>
        </Link>
      </li>

      <li
        className="dropDown"
        onClick={() => {
          productPage ? setProductPage(false) : setProductPage(true);
        }}
      >
        <HiArrowCircleDown size={24}/>
        <p>Product Admin</p>
      </li>
      {productPage && (
        <ul className="submenue">
          <li className={pathname === "/plans" ? "active" : null}>
            <Link to="/plans" onClick={()=>{setPathname("/plans")}}>
              <AiOutlineAreaChart/>

              <p>Plans</p>
            </Link>
          </li>
        </ul>
      )}

      <li className={pathname === "/notifications" ? "active" : null}>
        <Link to="/notifications" onClick={()=>{setPathname("/notifications")}}>
          <MdCircleNotifications size={24} />
          <p>Notifications</p>
        </Link>
      </li>

      <li className={pathname === "/ebooks" ? "active" : null}>
        <Link to="/ebooks" onClick={()=>{setPathname("/ebooks")}}>
          <IoBookSharp size={24} />
          <p>ebooks</p>
        </Link>
      </li>

      <li className={pathname === "/FAQs" ? "active" : null}>
        <Link to="/FAQs" onClick={()=>{setPathname("/FAQs")}}>
          <FcFaq size={24} color="black"/>
          <p>FAQs</p>
        </Link>
      </li>
    </ul>
  );
};

export default compose(
  withRouter,
  connect(
    (state) => {},
    (dispatch) => ({})
  )
)(Nav);
