import React, { useState } from "react";
import { Route, Router, Switch } from "react-router-dom";
import { connect, useSelector } from "react-redux";

import { setMobileNavVisibility } from "../../reducers/Layout";
import { withRouter } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";
import SideBar from "../../components/SideBar";
import Auth from "../Auth";
/**
 * Pages
 */
import Dashboard from "../Dashboard";

import Customers from "../Customers";
import AddCustomer from "../Customers/AddCustomer";

import UseCases from "../UseCases";
import AddUseCases from "../UseCases/AddUseCases";
import Notifications from "../Notification";
import AddNotification from "../Notification/AddNotification";
import Ebooks from "../EBooks";
import AddEbook from "../EBooks/AddEbook";
import Plans from "../Plans";
import AddPlans from "../Plans/AddPlans";
import "antd/dist/antd.css";
import { ToastContainer } from "react-toastify";
import { AiOutlineMenu } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import FAQs from "../FAQs";
import AddFAQ from "../FAQs/AddFAQ";
const Main = ({ mobileNavVisibility, hideMobileMenu, history, isLoggedIn }) => {
  history.listen(() => {
    if (mobileNavVisibility === true) {
      hideMobileMenu();
    }
  });
  const [mobileMenu, setMobileMenu] = useState(false);

  const userlogin = sessionStorage.getItem("user");
  return (
    <div
    // className={cx({
    //   "nav-open": mobileNavVisibility === true,
    // })}
    >
      {!isLoggedIn && !userlogin ? (
        <Auth />
      ) : (
        <div className="wrapper">
          {/* <div className="close-layer" onClick={hideMobileMenu}></div> */}
          <button className="menuBtn">
            {!mobileMenu ? (
              <AiOutlineMenu
                onClick={() => {
                  setMobileMenu(true);
                }}
              />
            ) : (
              <IoMdClose
                onClick={() => {
                  setMobileMenu(false);
                }}
              />
            )}
          </button>
          <SideBar mobileMenu={mobileMenu} />

          <div className="main-panel">
            <Header />
            <Switch>
              <Route path="/customers" component={Customers} />
              <Route
                path={["/addCustomer", "/editCustomer"]}
                component={AddCustomer}
              />

              <Route path="/useCases" component={UseCases} />
              <Route
                path={["/addUseCase", "/editUseCase/:id"]}
                component={AddUseCases}
              />
              <Route path="/notifications" component={Notifications} />

              <Route path="/plans" component={Plans} />
              <Route
                path={["/addPlan", "/editPlan/:id"]}
                component={AddPlans}
              />

              <Route path="/ebooks" component={Ebooks} />
              <Route
                path={["/addEbook", "/editEbook/:id"]}
                component={AddEbook}
              />

              <Route path="/FAQs" component={FAQs} />
              <Route path={["/addFAQ", "/editFAQ/:id"]} component={AddFAQ} />
              <Route
                path={["/addNotification", "/editNotification/:id"]}
                component={AddNotification}
              />

              <Route exact path="/" component={Dashboard} />
              {/* <Route path="/components" component={Components} /> */}
            </Switch>
            <Footer />
          </div>
        </div>
      )}
      {/* {isLoggedIn || userlogin && ( */}

      {/* )} */}

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="light"
      />
    </div>
  );
};

const mapStateToProp = (state) => ({
  mobileNavVisibility: state.Layout.mobileNavVisibility,
  isLoggedIn: state.Auth.isLoggedIn,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  hideMobileMenu: () => dispatch(setMobileNavVisibility(false)),
});

export default withRouter(connect(mapStateToProp, mapDispatchToProps)(Main));
