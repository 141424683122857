import React, { useState } from "react";
import { Link } from 'react-router-dom';
import "./CustomersDataTable.css";
import dateFormatter from "../../helpers/dateFormatter";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import IconBtn from "../IconBtn/IconBtn";
import _ from 'lodash';

const CustomersDataTable = ({
  customer,
  deleteCustomer
}) => {
  const [show, setShow] = useState(false);
  const openConfirmModalHandler = () => {
    setShow(true);
  };

  const deleteCustomerHandler = () => {
    deleteCustomer(customer.objectId);
  }

  return (
    <React.Fragment>
      <tr>
        <td>
          <img
            className="customer-img"
            src={customer.Logo}
            alt={`${customer.Name}`}
          />
        </td>
        <td>{customer.Name}</td>
        <td>{customer.Location}</td>
        <td dangerouslySetInnerHTML={{ __html: customer.Description }}
        ></td>
        <td>{customer.Website}</td>
        <td>
          <Link to={`/editCustomer/${customer.objectId}`}>
            <IconBtn
              title={"Edit"}
              iconName={"pencil"}
              btnBehaviourClass="edit"
            />
          </Link>
        </td>
        <td>
          <IconBtn
            title={"delete"}
            btnBehaviourClass={"delete"}
            iconName="trash"
            onClick={openConfirmModalHandler}
          />
        </td>

      </tr>

      {show && (
        <ConfirmModal
          handleClose={() => setShow(false)}
          handleShow={() => setShow(true)}
          message={`Are you sure you want to delete ${customer.Name}?`}
          setShow={setShow}
          show={show}
        >
          <button
            className="btn btn-danger"
            onClick={deleteCustomerHandler}
          >
            Delete
          </button>
        </ConfirmModal>
      )}
    </React.Fragment>
  );
};

export default CustomersDataTable;
