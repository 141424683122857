import React, { useState } from "react";
import { Link } from 'react-router-dom';
import "./CustomersDataTable.css";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import IconBtn from "../IconBtn/IconBtn";
import _ from 'lodash';
import { toast } from "react-toastify";
import toaster from "../../modules/toaster";

const FAQsDataTable = ({
  FAQ,
  deleteFAQ
}) => {
  const [show, setShow] = useState(false);
  const openConfirmModalHandler = () => {
    setShow(true);
  };

  const deleteFAQHandler = () => {
    try {
      deleteFAQ(FAQ.objectId);
toaster.success("deleted successfully")
    } catch (error) {
      toaster.error("something went erong")
    }
  }

  return (
    <React.Fragment>
      <tr>
       
        <td>{FAQ.question}</td>
        <td>{FAQ.answer}</td>
 
        <td>
          <Link to={`/editFAQ/${FAQ.objectId}`}>
            <IconBtn
              title={"Edit"}
              iconName={"pencil"}
              btnBehaviourClass="edit"
            />
          </Link>
        </td>
        <td>
          <IconBtn
            btnBehaviourClass={"delete"}
            iconName="trash"
            onClick={openConfirmModalHandler}
          />
        </td>

      </tr>

      {show && (
        <ConfirmModal
          handleClose={() => setShow(false)}
          handleShow={() => setShow(true)}
          message={`Are you sure you want to delete ${FAQ.question}?`}
          setShow={setShow}
          show={show}
        >
          <button
            className="btn btn-danger"
            onClick={deleteFAQHandler}
          >
            Delete
          </button>
        </ConfirmModal>
      )}
    </React.Fragment>
  );
};

export default FAQsDataTable;
