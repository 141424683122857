import React, { Component } from "react";
import { connect } from "react-redux";

class Footer extends Component {
  state = {};

  componentDidUpdate(prevProps) {
    if (this.props.error)
      if (
        this.props.error.response &&
        this.props.error.response.data &&
        this.props.error.response.data.message
      ) {
        this.showNotification(this.props.error.response.data.message);
      } else {
        this.showNotification("Something went wrong, try again later.");
      }
  }



  render() {
    return (
      <footer className="footer">
        <div className="container-fluid">
          <nav className="pull-left">
            <ul>
              <li>
                <a href="https://shrinkit.me" target="_blank" rel="noreferrer">
                  Home
                </a>
              </li>
            </ul>
          </nav>
          <p className="copyright pull-right">
            © 2023{" "}
            <a href="https://shrinkit.me" target="_blank" rel="noreferrer">
            shrinkit
            </a>
            , made with love for a better world
          </p>
        </div>
      </footer>
    );
  }
}

export default connect((state) => ({
}))(Footer);
