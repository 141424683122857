import "dotenv";
import * as AWS from "aws-sdk";
const uploadOnS3 = async (file, fileName, path) => {
  const s3 = new AWS.S3({
    accessKeyId: "AKIARUMASEGMEUGWN34W",
    secretAccessKey: "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",
    region: "us-east-1",
    params: {
      Bucket:"content.quantatel.com",
    },
  });
  const fileType = file.type;
  const fileNameWithExtension = `${fileName}.${file.name.split('.')[1]}`;
  const params = {
    Bucket:"content.quantatel.com",
    Key: `cdn.appgain.io/website/${fileNameWithExtension}`,
    Body: file,
    ACL: "public-read",
    ContentType: fileType,
  };
  try {
    const data = await s3.upload(params).promise();
    return data;
  } catch (err) {
    console.log(err, "err");
  }
};
export default uploadOnS3;
