import { appgainApi, getConfigHeaders, getConfigHeadersApi,  } from "../api";

const defaultState = {
  plans: [],
  plan: {},

  error: null,
  isPending: null,
  loaded: false,
  msg: "",
};

const url = "plans";

const EDIT_PLAN = "EDIT_PLAN";
const CREATE_PLAN = "CREATE_PLAN";
const DELETE_PLAN = "DELETE_PLAN";
const LOAD_PLANS_LIST = "LOAD_PLANS_LIST";
const GET_PLAN = "GET_PLAN";
const PLANS_PENDING = "PLANS_PENDING";
const PLANS_ERROR = "PLANS_ERROR";

// all PLANS
export const getPlans = () => async (dispatch, getState) => {
  dispatch({ type: PLANS_PENDING });
  try {
 
    const data = await appgainApi(
      `${url}`,
      "get",
      getConfigHeadersApi()
    );
    console.log(data,'data');
    dispatch({
      type: LOAD_PLANS_LIST,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PLANS_ERROR,
      error,
    });
  }
};
// get 1 PLAN
export const getPlan = id => async (dispatch, getState) => {
  dispatch({ type: PLANS_PENDING });
  try {
    const data = await appgainApi(
      `${url}/${id}`,
      "get",
      getConfigHeadersApi(),
   

    );
    dispatch({
      type: GET_PLAN,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: PLANS_ERROR, error });
  }
};
// EDIT PLAN
export const editPlan = (id,editPlanData) => async (dispatch, getState) => {
  dispatch({ type: PLANS_PENDING });
  try {
    await appgainApi(
      `${url}/${id}`,
      "put",
      editPlanData,
      getConfigHeadersApi(),
    );
    dispatch({
      type: EDIT_PLAN,
      payload: { id },
    });
  } catch (error) {
    dispatch({ type: PLANS_ERROR, error });
  }
};
// CREATE PLAN
export const createPlan = newPlanData => async (dispatch, getState) => {
  dispatch({ type: PLANS_PENDING });
  try {
    const res = await appgainApi(
      `${url}`,
      "post",
      newPlanData,
      getConfigHeadersApi()
      // headers:{

      // }
   
    );
    dispatch({
      type: CREATE_PLAN,
      payload: res,
    });
  } catch (error) {
    dispatch({ type: PLANS_ERROR, error });
  }
};
// DEACTIVE
export const deletePlan = (id) => async (dispatch, getState) => {
  console.log(id,'id');
  dispatch({ type: PLANS_PENDING });
  try {
    await appgainApi(`${url}/${id}`, "delete", getConfigHeadersApi());
    dispatch({
      type: DELETE_PLAN,
      payload: id,
    });
  } catch (error) {
    dispatch({ type: PLANS_ERROR, error });
  }
};


export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case LOAD_PLANS_LIST:
      return {
        ...state,
        isPending: false,
        error: false,
        plans: action.payload,
        loaded: true,
      };
    case GET_PLAN:
      return {
        ...state,
        plan: action.payload,
        loaded: true,
        isPending: false,
      };
    case PLANS_PENDING:
      return {
        ...state,
        isPending: true,
        error: false,
        loaded: false,
      };

    case PLANS_ERROR:
      return {
        ...state,
        isPending: false,
        error: action.error,
        plans: [],
        loaded: true,
      };

    case CREATE_PLAN:
      return {
        ...state,
        isPending: false,
        error: false,
        plans: [...state.plans, action.payload],
        loaded: true,
      };

    case EDIT_PLAN:
      return {
        ...state,
        isPending: false,
        error: false,
        loaded: true,
        plans: state.plans.map(plan =>
            plan.id == action.payload.id ? action.payload.newPlanData : plan
        ),
      };
   
    case DELETE_PLAN:
      return {
        ...state,
        isPending: false,
        error: false,
        plans: state.plans.filter(plan => plan.id != action.payload),
        loaded: true,
      };
    default:
      return state;
  }
}
