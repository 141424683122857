import React, { useEffect, useState } from "react";
import "./UseCases.css";
import { connect } from "react-redux";
import { getUseCases, deleteUseCase } from "../../reducers/UseCases";
import UseCasesDataTable from "../../components/UseCasesDataTable";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import _ from "lodash";

const UseCase = ({
  useCases,
  getUseCases,
  deleteUseCase,
  isPending,
  history,
}) => {
  const [data, setData] = useState([]);
  console.log(data);

  useEffect(() => {
    getUseCases();
  }, []);

  useEffect(() => {
    setData(useCases);
  }, [useCases]);

  const handleAddCase = () => {
    history.push("addUseCase");
  };
  function reload(){

    try {
      
        fetch(
        'https://api.vercel.com/v1/integrations/deploy/prj_BS7SQM7MgEw1poSMDY7TEuiTlljx/T2fPlgiLNi',{
        method:'GET',
       headers: {
          'Content-Type': 'application/json',
        },
       })
       alert('publishing done successfully')

      } catch (error) {
        console.log("error", error);
        alert('failed')

    }
  }
  if (isPending) {
    return <LoadingSpinner />;
  }
  return (
    <div className='card'>
      <div className='header'>
        <h4 className='title'>
          UseCase
          <button
            onClick={reload}
            className="btn btn-primary addProjectBtn me-2"
          >
            publish to the website
          </button> 
          <button
            onClick={handleAddCase}
            className='btn btn-primary addProjectBtn'
          >
            <i className='fa fa-plus'></i> Add a UseCase
          </button>
        </h4>
      </div>
      <div
        className='content table-responsive table-full-width'
        style={{ width: "100%" }}
      >
        <table className='table table-hover table-striped'>
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Category</th>

              
              <th>Industry</th>
              <th>Content</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {
              data?.map(useCase => (
                <UseCasesDataTable
                  key={useCase.objectId}
                  useCase={useCase}
                  isPending={isPending}
                  deleteUseCase={deleteUseCase}
                />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default connect(
  state => ({
    useCases: state.UseCases.useCases,
    isPending: state.UseCases.isPending,
    useCase: state.UseCases.useCase,
  }),
  dispatch => ({
    getUseCases: () => dispatch(getUseCases()),
    deleteUseCase: id => dispatch(deleteUseCase(id)),
  })
)(UseCase);
