import React, { useEffect, useState } from "react";
import "./Customers.css";
import { connect } from "react-redux";

import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import _ from 'lodash';
import { getFAQs ,deleteFAQ} from "../../reducers/FAQs";
import FAQsDataTable from "../../components/FAQsDataTable";

const FAQs = ({
  FAQs,
  getFAQs,
  deleteFAQ,
  isPending,
  history,
}) => {

  const [data, setData] = useState([]);

  useEffect(() => {
    getFAQs();
  }, []);

  useEffect(() => {
    setData(FAQs);
  }, [FAQs])

  const handleAddFAQ = () => {
    history.push('addFAQ');
  }

  if (isPending) {
    return <LoadingSpinner />;
  }
  function reload(){

    try {
      
        fetch(
        'https://api.vercel.com/v1/integrations/deploy/prj_BS7SQM7MgEw1poSMDY7TEuiTlljx/T2fPlgiLNi',{
        method:'GET',
       headers: {
          'Content-Type': 'application/json',
        },
       })
       alert('publishing done successfully')

      } catch (error) {
        console.log("error", error);
        alert('failed')

    }
  }
  return (
    <div className="card">
      <div className="header">
        <h4 className="title">
        FAQs
          <button
            onClick={reload}
            className="btn btn-primary addProjectBtn me-2"
          >
            publish to the website
          </button> 
          <button
            onClick={handleAddFAQ}
            className="btn btn-primary addProjectBtn"
          >
            <i className="fa fa-plus"></i> Add new question
          </button>
        </h4>
      </div>
      <div
        className="content table-responsive table-full-width"
        style={{ width: "100%" }}
      >
        <table className="table table-hover table-striped">
          <thead>
            <tr>
              <th>question</th>
              <th>answer</th>
             
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            { data?.map((FAQ) => (
              <FAQsDataTable
                key={FAQ.objectId}
                FAQ={FAQ}
                isPending={isPending}
                deleteFAQ={deleteFAQ}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    FAQs: state.FAQs.FAQs,
    isPending: state.FAQs.isPending,
    FAQ: state.FAQs.FAQ,
  }),
  (dispatch) => ({
    getFAQs: () => dispatch(getFAQs()),
    deleteFAQ: id => dispatch(deleteFAQ(id))
  })
)(FAQs);
