import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./EbooksDataTable.css";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import IconBtn from "../IconBtn/IconBtn";

const EbooksDataTable = ({ ebook, deleteEbook }) => {
  const [show, setShow] = useState(false);
  const openConfirmModalHandler = () => {
    setShow(true);
  };

  const deleteEbookHandler = () => {
    deleteEbook(ebook.objectId);
  };

  return (
    <React.Fragment>
      <tr>
        <td>
          <img className="team-img" src={ebook.image} alt={`${ebook.title}`} />
        </td>
        <td>{ebook.title}</td>
        <td>{ebook.description}</td>
        <td>{ebook.link}</td>
        <td>{ebook.category}</td>
        <td title="Wanna make it featured or not ?">{ebook.featured ? "true": "false"}</td>

        <td className="action-icon">
          <Link to={`/editEbook/${ebook.objectId}`} className="action-icon">
            <IconBtn
              title={"Edit"}
              iconName={"pencil"}
              btnBehaviourClass="edit"
              
            />
          </Link>
        </td>
        <td className="action-icon">
          <IconBtn
            btnBehaviourClass={"delete"}
            iconName="trash"
            onClick={openConfirmModalHandler}
            className="action-icon"
          />
        </td>
      </tr>

      {show && (
        <ConfirmModal
          handleClose={() => setShow(false)}
          handleShow={() => setShow(true)}
          message={`Are you sure you want to delete ${ebook.Name}?`}
          setShow={setShow}
          show={show}
        >
          <button className="btn btn-danger" onClick={deleteEbookHandler}>
            Delete
          </button>
        </ConfirmModal>
      )}
    </React.Fragment>
  );
};

export default EbooksDataTable;
